<template>
  <v-card>
    <v-card-title
      :class="{ 'px-1': $vuetify.breakpoint.xsOnly }"
    >
      <span class="headline pt-1">
        {{ !id ? 'New product template' : `${element ? element.name : ''}` }}
      </span>
      <v-chip
        v-if="id"
        small
        class="mx-3 mt-1"
        outlined
      >
        editing
      </v-chip>
    </v-card-title>
    <v-card-text :class="{ 'px-1': $vuetify.breakpoint.xsOnly }">
      <div class="d-flex">
        <FileUpload
          class="mr-3"
          :existing-file="imageUrl"
          :url="`${$baseURL}/api/templates/${id}/image`"
          method="PUT"
          file-type="image"
          width="400"
          height="200"
          :disabled-text="!id ? 'Please create the template first, then open in Edit to upload Image' : undefined"
          @uploaded="e => imageUrl = e"
        />
        <FileUpload
          :existing-file="formData.epd && formData.epd.includes('cpd-pathfinder') ? formData.epd : null"
          :url="`${$baseURL}/api/templates/${id}/epd`"
          method="PUT"
          file-type="pdf"
          :empty-message="formData.epd ? 'External EPD link provided (see below).' : 'No EPD uploaded yet'"
          width="400"
          height="200"
          :disabled-text="!id ? 'Please create the template first, then open in Edit to upload PDF' : undefined"
          @uploaded="e => formData.epd = e"
        />
      </div>
      <ValidationObserver
        ref="observer"
        v-slot="{ handleSubmit, errors, invalid, dirty }"
      >
        <v-form
          ref="form"
          @submit.prevent="handleSubmit(handleFormSubmit)"
        >
          <v-row>
            <FormItem
              v-for="item in formItems"
              :key="item.name"
              v-model="formData[item.field]"
              :span="item.span"
              :name="item.name"
              :field="item.field"
              :suffix="item.suffix"
              :min="item.min"
              :caption="item.caption"
              :placeholder="item.placeholder"
              :type="item.type"
              :items="item.items"
              :rules="item.rules"
              :displayed-errors="formErrors.find((x) => x.field === item.field) && formErrors.find((x) => x.field === item.field).errors"
            />
          </v-row>
          <v-row>
            <FormErrors
              :vee-errors="errors"
              :other-error="submissionError"
            />
          </v-row>
          <v-row>
            <v-spacer />
            <v-btn
              type="submit"
              class="mx-5 my-2"
              color="shamrock white--text"
              elevation="0"
              large
              :disabled="invalid || !dirty"
              :loading="saving"
            >
              Save product
            </v-btn>
          </v-row>
        </v-form>
      </ValidationObserver>
    </v-card-text>
  </v-card>
</template>

<script>
import FormItem from '@/components/form/FormItem'
import productFormItems from './productFormItems.json'
import debounce from 'lodash.debounce'
import FileUpload from '@/components/modals/FileUpload'

import { createNamespacedHelpers } from 'vuex'
import FormErrors from '@/components/form/FormErrors'
import { ValidationObserver } from 'vee-validate'
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('elements')

const getDefaultForField = (x) => {
  if (x.type === 'number') { return 0 }
  if (x.type === 'text') { return '' }
  if (x.type === 'select') {
    return x.items[0]
  }
  throw new Error(`Unable to get default for ${x.field}`)
}

const defaultFormData = Object.fromEntries(productFormItems.map((x) => [x.field, x.default || getDefaultForField(x)]))

export default {
  name: 'ProductTemplateForm',
  components: {
    FormItem,
    FileUpload,
    FormErrors,
    ValidationObserver
  },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      formItems: productFormItems,
      formData: { ...defaultFormData },
      formErrors: [],
      submissionError: null,
      submittedOnce: false,
      saving: false,
      imageUrl: null
    }
  },
  computed: {
    ...mapGetters(['element'])
  },
  watch: {
    formData: {
      deep: true,
      handler () {
        if (this.submittedOnce) {
          this.debouncedValidate()
        }
      }
    }
  },
  async created () {
    const { data: materialCategories } = await this.$axios.get('/element_categories')
    const furnishingsCategory = materialCategories.find((x) => x.name.toLowerCase() === 'furnishings')
    this.formItems.find((x) => x.field === 'productType').items = furnishingsCategory.subCategories
    if (this.id) {
      await this.loadExistingElement({ id: this.id, systemOfMeasurement: 'metric' })
      const { name, suggestionsCategory, manufacturer, kgCo2PerKmShipped, webLink, kgCo2PerProductUnit, image, epd, replacements } = this.element
      this.imageUrl = image
      this.formData = {
        name,
        epd,
        manufacturer,
        kgCo2PerKmShipped,
        webLink,
        productType: suggestionsCategory,
        co2PerUnit: kgCo2PerProductUnit,
        replacements
      }
    }
  },
  methods: {
    ...mapMutations(['resetElementState']),
    ...mapActions(['loadExistingElement']),

    async validate () {
      const validationResults = await Promise.all(this.$refs.form.$children.map(async (formItem) => {
        const valid = await this.$refs.observer.validate()
        if (valid) { return null }
        return {
          field: formItem.field,
          errors: formItem.errors.collect(formItem.name)
        }
      }))
      this.formErrors = validationResults.filter((x) => x)
      return this.formErrors.length === 0
    },
    debouncedValidate: debounce(function () { return this.validate }, 150),
    async handleFormSubmit () {
      this.submittedOnce = true
      const valid = await this.validate()
      if (!valid) {
        this.submissionError = 'Please fix the above errors'
        return
      }
      const { manufacturer, kgCo2PerKmShipped, co2PerUnit, webLink, name, epd, productType, replacements, cpdVersion } = this.formData
      // const locationLookup = {
      //   Vestre: [30, 20]
      // }
      // if (!locationLookup[manufacturer]) {
      //   throw new Error(`No location found for manufacturer, Vestre`)
      // }
      const template = {
        isTemplate: true,
        activeLayout: 0,
        cpdVersion,
        totalQuantity: 0,
        totalCo2Kg: 0,
        name,
        image: this.imageUrl,
        epd,
        category: 'Furnishings',
        suggestionsCategory: productType,
        type: 'material',
        manufacturer,
        kgCo2PerKmShipped,
        co2MeasuredPer: 'unit',
        webLink,
        layouts: [
          {
            dimensions: [
              {
                units: {
                  metric: { symbol: 'quantity', toSi: 1 },
                  imperial: { symbol: 'quantity', toSi: 1 }
                },
                valueRange: [0, 100],
                value: 0,
                label: 'Quantity',
                type: 'count',
                ref: '$u',
                editable: true,
                fixedValueFormula: null
              }
            ],
            formula: '$u'
          }
        ],
        replacements,
        kgCo2PerProductUnit: Number(co2PerUnit),
        formulaForOneTime: `$qty * ${co2PerUnit} * ($replacements + 1)`
        // sourceLocation: locationLookup[manufacturer]
      }
      this.saving = true
      try {
        if (this.id) {
          await this.$axios.put(`/templates/${this.id}`, { updates: template })
          this.resetElementState()
          this.$store.dispatch('showSnackbar', `Updated product template successfully.`)
        } else {
          await this.$axios.post('/templates', template)
          this.$store.dispatch('showSnackbar', `Created product template successfully. "${name}" is now available for users to add to their projects.`)
        }
        this.formData = defaultFormData
        this.$router.push('/admin/templates')
      } catch (err) {
        this.submissionError = err.message
        this.saving = false
        throw err
      }
    }
  }
}
</script>

<style>

</style>
