<template>
  <v-col
    :cols="span * 12"
    :sm="span * 6"
    :lg="span * 4"
    :xl="span * 3"
  >
    <div
      class="title"
      style="min-height: 55px;"
    >
      {{ name }}
      <span class="error--text">*</span>
      <div class="caption">
        {{ caption }}
      </div>
    </div>
    <ValidationProvider
      v-slot="{ errors }"
      :name="name"
      :rules="rules"
    >
      <component
        :is="standardTypeTags[type].tag"
        v-if="standardTypeTags[type]"
        :value="value"
        :type="standardTypeTags[type].type"
        :error-messages="errors"
        :name="name"
        :min="min"
        :suffix="suffix"
        :data-vv-name="name"
        class="mt-0 pt-0 pb-0"
        single-line
        :placeholder="placeholder"
        :items="items"
        @input="e => $emit('input', e)"
        @change="e => $emit('input', e)"
      />
    </ValidationProvider>
  </v-col>
</template>

<script>
import { VTextField, VSelect } from 'vuetify/lib'
import { ValidationProvider } from 'vee-validate'

const standardTypeTags = {
  text: { tag: 'VTextField', type: 'text' },
  number: { tag: 'VTextField', type: 'number' },
  select: { tag: 'VSelect' }
}

export default {
  name: 'FormItem',
  components: {
    VTextField,
    VSelect,
    ValidationProvider
  },
  props: {
    type: {
      validator: (val) => [...Object.keys(standardTypeTags)].includes(val),
      default: 'input'
    },
    name: { type: String, required: true },
    field: { type: String, required: true },
    caption: { type: String, default: '' },
    suffix: { type: String, default: '' },
    span: { type: Number, default: 1 },
    min: { type: Number, default: undefined },
    value: { type: [String, Number], required: false, default: null },
    placeholder: { type: String, default: '' },
    rules: { type: [String, Object], default: '' },
    items: { type: Array, default: () => [] },
    displayedErrors: { type: Array, default: () => [] }
  },
  data () {
    return {
      standardTypeTags
    }
  },
  computed: {
  }
}
</script>

<style>

</style>
